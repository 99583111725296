import { structureType } from '~/constants'
import { freeWordArrayEnum, inputParameterEnum } from '~/constants/input-parameter'
import type { ILora, ILoraStructure, IOption } from '~/interfaces'

type promptKey = inputParameterEnum | freeWordArrayEnum

type IPrompt = {
  [inputParameterEnum.lineDetail]: IOption
  [inputParameterEnum.structureType]: IOption
  [inputParameterEnum.spaceType]?: IOption
  [inputParameterEnum.styleType]?: IOption
  [inputParameterEnum.lora]?: ILora
  [inputParameterEnum.materialTypeOne]?: IOption
  [inputParameterEnum.materialTypeTwo]?: IOption
  [freeWordArrayEnum.freeWordArray]: string[]
  [freeWordArrayEnum.materialTypeOneFreeWordArray]: string[]
  [freeWordArrayEnum.materialTypeTwoFreeWordArray]: string[]
}
const initialPrompt: IPrompt = {
  [inputParameterEnum.lineDetail]: { en: 'detailed', ja: '3D/写真' },
  [inputParameterEnum.structureType]: { ...structureType.interior },
  [inputParameterEnum.spaceType]: undefined,
  [inputParameterEnum.styleType]: undefined,
  [inputParameterEnum.lora]: undefined,
  [inputParameterEnum.materialTypeOne]: undefined,
  [inputParameterEnum.materialTypeTwo]: undefined,
  [freeWordArrayEnum.freeWordArray]: [],
  [freeWordArrayEnum.materialTypeOneFreeWordArray]: [],
  [freeWordArrayEnum.materialTypeTwoFreeWordArray]: [],
}

export const usePromptStore = defineStore('prompt', () => {
  const isLoraVersionLoading = ref(false)
  const loraStructure = ref<ILoraStructure>({ title: 'バージョン', options: [] })

  const freeWordArrayInput = ref<string | undefined>(undefined)
  const materialTypeOneFreeWordArrayInput = ref<string | undefined>(undefined)
  const materialTypeTwoFreeWordArrayInput = ref<string | undefined>(undefined)

  const isInputComplete = computed(() => {
    return materialTypeOneFreeWordArrayInput.value === undefined
      && materialTypeTwoFreeWordArrayInput.value === undefined
      && freeWordArrayInput.value === undefined
  })

  const prompt = reactive<IPrompt>(structuredClone(initialPrompt))

  const getPromptWithCurrentInputs = () => {
    const rawPrompt = toRaw(prompt)
    const currentPrompt = {
      ...rawPrompt,
      materialTypeOneFreeWordArray: [...(rawPrompt.materialTypeOneFreeWordArray || [])],
      materialTypeTwoFreeWordArray: [...(rawPrompt.materialTypeTwoFreeWordArray || [])],
      freeWordArray: [...(rawPrompt.freeWordArray || [])],
    }

    if (materialTypeOneFreeWordArrayInput.value) {
      currentPrompt.materialTypeOneFreeWordArray.push(materialTypeOneFreeWordArrayInput.value)
    }

    if (materialTypeTwoFreeWordArrayInput.value) {
      currentPrompt.materialTypeTwoFreeWordArray.push(materialTypeTwoFreeWordArrayInput.value)
    }

    if (freeWordArrayInput.value) {
      currentPrompt.freeWordArray.push(freeWordArrayInput.value)
    }

    return currentPrompt
  }

  const toggleLoadingStatus = () => {
    isLoraVersionLoading.value = !isLoraVersionLoading.value
  }

  const fetchLoraVersion = async () => {
    toggleLoadingStatus()
    const structure = prompt[inputParameterEnum.structureType]?.en
    const space = prompt[inputParameterEnum.spaceType]?.en
    const style = prompt[inputParameterEnum.styleType]?.en

    if (!structure || !space || !style) {
      toggleLoadingStatus()
      return []
    }

    const { $customFetch } = useNuxtApp()
    const loraPresets = await $customFetch('/api/lora-picker', {
      method: 'POST',
      body: {
        structure,
        style,
        space,
      },
    })
    const options: ILora[] = loraPresets.map((lora) => {
      return {
        id: lora.id,
        ja: lora.version,
        en: lora.version,
        loraId: lora.loraId,
      }
    })

    prompt[inputParameterEnum.lora] = options[0]

    toggleLoadingStatus()
    return options
  }

  const resetPromptProperty = () => {
    Object.assign(prompt, structuredClone(initialPrompt))
  }

  const updatePromptProperty = async (key: promptKey, value?: IOption | ILora) => {
    switch (key) {
      case inputParameterEnum.lineDetail: {
        if (value) {
          prompt[key] = value
          loraStructure.value.options = await fetchLoraVersion()
        }
        return
      }
      case inputParameterEnum.structureType :{
        if (prompt[inputParameterEnum.structureType].en !== value?.en) {
          const tmpLineDetail = prompt[inputParameterEnum.lineDetail]
          const newStructureType = value as IOption
          resetPromptProperty()
          prompt[key] = newStructureType
          prompt[inputParameterEnum.lineDetail] = tmpLineDetail
        }
        return
      }
      case inputParameterEnum.spaceType:
      case inputParameterEnum.styleType: {
        prompt[inputParameterEnum.lora] = undefined
        const newValue: IOption | undefined = prompt[key]?.en === value?.en
          ? undefined
          : value;
        (prompt[key] as IOption | undefined) = newValue

        loraStructure.value.options = await fetchLoraVersion()
        return
      }
      case inputParameterEnum.lora: {
        prompt[inputParameterEnum.lora] = value as (ILora | undefined)
        return
      }
      case inputParameterEnum.materialTypeOne:
      case inputParameterEnum.materialTypeTwo: {
        const newValue: IOption | undefined = prompt[key]?.en === value?.en
          ? undefined
          : value;
        (prompt[key] as IOption | undefined) = newValue
      }
    }
  }

  const addPromptFreeWordArray = (key: freeWordArrayEnum, value: string) => {
    prompt[key].push(value)
  }

  const deletePromptFreeWordArray = (key: freeWordArrayEnum, index: number) => {
    if (prompt[key].length > 0) {
      prompt[key].splice(index, 1)
    }
  }

  return {
    loraStructure,
    isLoraVersionLoading,
    prompt,
    freeWordArrayInput,
    materialTypeOneFreeWordArrayInput,
    materialTypeTwoFreeWordArrayInput,
    getPromptWithCurrentInputs,
    isInputComplete,
    resetPromptProperty,
    updatePromptProperty,
    addPromptFreeWordArray,
    deletePromptFreeWordArray,
    fetchLoraVersion,
    toggleLoadingStatus,
  }
}, { persist: true })
